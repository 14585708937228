#SkapaWrapper h1{
    text-align: center;
    margin-top: 5vh;
}

.formContainer{
    display:flex;
    flex-direction: column;
    padding: var(--main-margin);
    box-shadow: var(--shadow-light);
    border-radius: var(--main-border-radius);
    align-items: center;
}

.formTop{
    display:flex;
    flex-direction: row;
    justify-content: center;
}

.dateMade{
    text-align: center;
}
.dates{
    display:flex;
    flex-direction: row;
    justify-content: center;
}
.dates input{
    width: 20%;
    margin-left: 10px;
}

#EditorWrapper{
    margin-bottom: var(--input-margin);
}
.middleSpace{
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}
.middleSpace img{
    width: 30%;
}

.projectInit, .projectGadgets{
    display:flex;
    flex-direction: column;
    width:40%;
}

.formDivider{
    height:2px;
    background-color: var(--accent-purple);
    width:100%;
    margin: var(--main-margin) 0px 20px 0px;
    border-radius:5px;
}

.formBottom{
    display:flex;
    flex-direction: column;
    width: 40%;
    align-items: center;
    position:relative;
}

/*PREVIEW*/

#PreviewWrapper{
    margin-top:50vh;
    position: absolute;
    width: 100vw;
    height: 250vh;
    backdrop-filter: blur(5px);
    display:flex;
    justify-content: center;
    align-items: center;
    top: -50vh;
    z-index: 10;
}

#ArtWrapper{
    margin-top:20vh;
    width: var(--main--page-width);
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 0px 50px 0px;
    border-radius: var(--main-border-radius);
}

#ArtWrapper img{
    width: 50%;
    border-radius: 10px;
}

.upperInfo{
    display:flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

#ArtWrapper .textBody{
    width: 40%;
}

#ArtWrapper .projektGadgets{
    margin:  20px 0px var(--main-margin) 0px;
    display:flex;
    align-items: center;
    flex-direction: column;
}

.factsBox li,
.OBSListWrapper li
{
    list-style: none;
    position: relative;
    bottom: 5px;
}

.OBSListWrapper li > div{
    background-image: url("../media/green-wool.png");
    background-repeat: no-repeat;
    background-size: 25px;
    background-position: left center;
    display: inline-block;
    height:25px;
    width:25px;
    margin-right: 10px;
    position: relative;
    bottom:-6px;
}

.factsBox .listPic, 
.OBSListWrapper .listpic{
    background-image: url("../media/purple_wool.png");
    background-repeat: no-repeat;
    background-size: 25px;
    background-position: left center;
    display: inline-block;
    height:25px;
    width:25px;
    margin-right: 10px;
    position: relative;
    bottom:-6px;
}
#ArtWrapper .buttonContainer{
    display: flex;
    flex-direction: row;
    width: 45%;
    justify-content: space-around;
    margin-top: 20px;
}


#ArtWrapper .edit{
    color: var(--accent-purple);
    border-color: var(--accent-purple);
}
#ArtWrapper .edit:hover{
    color: black;
    background-color: var(--accent-purple);
}


.openIstrue{
    height: 250vh;
}
main #SkapaWrapper .openIstrue{margin-top:0px;}
.openIsfalse{
    height: 100vh;
}

#EditorWrapper .tox-sidebar-wrap{
    background-color: var(--input-bgc);
}

#OBSWrapper{
    width: 70%;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: var(--shadow-light);
    padding: 20px;
    border-radius: var(--main-border-radius);
}

#OBSWrapper button {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

/* On screens that are 732 or less */
@media screen 
    and (max-width: 732px) 
    and (min-width: 300px) 
{ 
    #OBSWrapper{
        width: 80vw;
    }
    #OBSWrapper h3{
        font-size: 16px;
        text-align: center;
    }
    #OBSWrapper ul{
        padding: 0px;
    }
    .formContainer{
        width: 80vw;
        padding: 75px;
    }
    .formTop{
        flex-direction: column;
    }
    .formTop .middleSpace{
        flex-direction: row;
    }
    .projectInit, .middleSpace, .projectGadgets{
        width: 100%;
        padding-bottom: var(--main-margin);
    }
    #PreviewWrapper #ArtWrapper{
        margin: 0px;
        position: relative;
        bottom: 30vh;
    }
    #ArtWrapper{
        width: 90%;
    }
    #ArtWrapper .buttonContainer{
        width: 80%;
    }
    #ArtWrapper .buttonContainer .edit{
        margin-right: 10px;
    }
}