/*brukar bli så kladdigt med animationseffekter*/

/* .stroke */
.stroke a, .strokeText,
.stroke :after, .strokeText :after,
.stroke:before, .strokeText:before {
  transition: all .5s;
}
.stroke a:hover, .strokeText:hover {
  color: var(--white);
}

.stroke a:after{
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 0%;
  content: '.';
  color: transparent;
  background: var(--white);
  height: 1px;
}
.strokeText :after{
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 0%;
  content: '.';
  color: transparent;
  background: var(--white);
  height: 1px;
}
.stroke a:hover:after, .strokeText:hover:after {
  width: 100%;
}


/*BUTTONS*/
button {
  transition: all .5s ease;
  color: var(--accent-green);
  border: 3px solid var(--accent-green);
  font-family:'Montserrat', sans-serif;
  text-transform: uppercase;
  text-align: center;
  line-height: 1;
  font-size: 17px;
  background-color : transparent;
  padding: 10px;
  outline: none;
  border-radius: 4px;
}
button:hover {
  color: black;
  background-color: var(--accent-green);
}
.purpleBtn{
  border-color: var(--accent-purple);
  color: var(--accent-purple);

}
.purpleBtn:hover{
  background-color: var(--accent-purple);
  color: black;
}

