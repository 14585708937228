#projektWrapper{
    width: var(--main--page-width);
}

#projektWrapper h1{
    margin-bottom: var(--main-margin);
}

/*PROJEKT I LISTAN /PROJEKT*/

#projektListWrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.oneProjektWrapper{
    background-color: var(--accent-purple);
    width: 100%;
    display:flex;
    flex-direction: row;

    transition: bottom ease-in .3s;
    transition: box-shadow ease-in .1s;

    position:relative;
    bottom: 0px;
    padding: 20px;
    box-shadow: var(--shadow-light);
    margin-bottom: var(--main-margin);
    border-radius: var(--main-border-radius-s);
}

.oneProjektWrapper a {
    text-decoration: none;
}

.oneProjektWrapper:hover{
    position:relative;
    bottom: 1vh;
    box-shadow: var(--shadow-dark);
}

.oneProjektWrapper img{
    width: 20vw;
    height:50vh;
    background-position: center;
    background-size:contain;
}

.projektInfo{
    display:flex;
    flex-direction: row;
    justify-content: space-around;
    /*fick inte till storleken på bredden skulle följa föräldern
    så den får vara en vw istället sålänge*/
    width: var(--main--page-width);
}

.projektText{
    display:flex;
    flex-direction: column;
    width: 50%;
    height: 60%;
    color: var(--main-beige);
}
.projektText p{
    margin: 5px;
    
}
.projektText a{color: var(--main-beige);}

.projektText h2{color: var(--main-white)}

.oneProjektWrapper .projektText{
    display:flex;
    justify-content: start;
} 
.oneProjektWrapper img{
    display:flex;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 35%;
    height: 60%;
    border-radius: 20px;
    position: relative;
    border-color: 2px var(--accent-purple);

}

/*EN INKLICKAD ARTIKEL */
#projektArtWrapper{
    width: var(--main--page-width);
    background-color: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 0px 50px 0px;
    border-radius: var(--main-border-radius);
}
.oneProjektArtWrapper{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.oneProjektArtWrapper .upperInfo{
    display:flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.upperInfo a{
    color: var(--main-purple);
    transition: color .1s ease-in;
}
.upperInfo a:hover{
    color: var(--accent-purple);
    cursor: pointer;
}

.oneProjektArtWrapper img{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    border-radius: var(--main-border-radius);
}

.oneProjektArtWrapper .textBody{
    width:70%;
}

#projektArtWrapper .projektGadgets{
    margin-top: 20px;
    display:flex;
    align-items: center;
    flex-direction: column;
}

.oneProjektArtWrapper.factsBox li{
    list-style: none;
    position: relative;
    bottom: 5px;
}

.factsBox li{
    list-style: none;
    position: relative;
    bottom: 5px;
}
.factsBox{
    /*line-break: 1px; vet inte hur denna funkar*/
    background-color: var(--main-green);
    padding: 5px 30px 5px 0px;
    border-radius: var(--main-border-radius);
}

#ProjektGridWrapper{
    flex-direction: column;
}
#ProjektGridWrapper h1{
    text-align: center;
}

/* On screens that are 732 or less */
@media screen 
    and (max-width: 732px) 
    and (min-width: 300px) 
{ 
    .projektText h2{
        font-size: 16px;
    }
    .oneProjektArtWrapper .textBody,
    .oneProjektArtWrapper img{
        width: 90%;
    }

}