/*ingen margin på framsidan*/
#homeWrapper{
    width:100%;
    margin-top:0px;
}

/*VIDEO*/
#videoWrapper{
    position:relative;
    height: calc(100vh - 80px);
    margin-bottom: 200px;
    width:100%;
}

.gradientWrapper {
    position: absolute;
    width: 100%;
}

.gradient {
    background: rgba(255, 255, 255, 0) linear-gradient(to bottom, rgba(0, 0, 0, 0) 10%, rgba(255, 255, 255, .1) 40%, rgba(255, 255, 255, .5) 75%, rgba(255, 255, 255, 1) 100%) repeat scroll 0 0;
    width: 100%;
    height: calc(100vh - 80px);
    min-height: 600px;
    object-fit: cover;
    position: relative;
    z-index: 2;
}

video {
    position: absolute;
    top: 0;
    z-index: -1;
    width: 100%;
    height: calc(100vh - 80px);
    min-height: 600px;
    object-fit: cover;
}

/*TITEL*/
.introWrapper{
    margin-left: 5vw;
    position: absolute;
    bottom: -10vh;
    z-index: 5;
}
.introWrapper h1{
    margin-top:0px;
    font-size: 80px;

}
.introWrapper > div{
    width: 40vw; 
}

.introWrapper > button{
    border-color: var(--main-green);
}


/*ARTIKLAR(?)*/
.article{
    transition:all 0.5s ease-in;
}
.article:hover{
    box-shadow: var(--shadow);
}

/*PROJEKTGRID*/
#ProjektGridWrapper{
    width: var(--main--page-width);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

#gridWrapper{
    width: 100%;
    display:grid;
    grid-gap:20px;

}

#gridWrapper :nth-child(1) {
    grid-column-start: 1;
    grid-column-end: 6;
    grid-row-start: 1;
    grid-row-end: 3;
}
#gridWrapper :nth-child(2) {
    grid-column-start: 6;
    grid-column-end: 10;
    grid-row-start: 1;
    grid-row-end: 2;
}
#gridWrapper :nth-child(3) {
    grid-column-start: 6;
    grid-column-end: 10;
    grid-row-start: 2;
    grid-row-end: 3;
}
#gridWrapper .oneGrid {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
}
.oneGrid .card {
    text-decoration: none;
    color: #444;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    min-height: 100%;
    position: relative;
    top: 0;
    transition: all 0.1s ease-in;
}
.oneGrid .card:hover {
    top: -2px;
    box-shadow: 0 4px 5px rgba(0, 0, 0, 0.2);
}
.oneGrid .card {
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    padding: 30px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border-radius: 10px;
}

.oneGrid .text {
    text-shadow: #000000 0px 2px 10px;
    color: var(--main-beige);
    position: relative;
    z-index: 10;
}

.oneGrid .text h2{
    margin-bottom: 0px;
    color: var(--white);
}

.oneGrid p {
    flex: 1;
    line-height: 1.4;
}

#gridWrapper .gradient {
    top: 0;
    left: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.79) 100%);
}

.info-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 20px;
}

/* On screens that are 732 or less */
@media screen 
    and (max-width: 732px) 
    and (min-width: 300px) 
{ 
    #gridWrapper{
        display: flex;
        flex-direction: column;
    }
    .oneGrid{
        width: 100%;
    }
    .info-container{
        padding: 30px 0px 0px 0px;
    }
    .info-container h5{
        margin-bottom: 0px;
    }
}