/*denna fil har regler som förekommer på alla sidor av appen.*/

@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Ruwudu&display=swap');
/*fina fonter värda att ta en titt på:
Quicksand, Lora, 

/*CUSTOM VARIABLES*/
:root{
    /*COLORS*/
    --main-purple: #6F6FB3;
    --main-beige: #FFE1D1;
    --accent-purple: #B7B7FF;
    --main-green: #B5FF9E;
    --accent-green: #77B664;
    --main-white: white;
    --input-bgc: #f9f9f9;

    --white: white;

    /*FONTS*/
    --main-title-text: 'Montserrat', sans-serif;
    --main-body-text: 'Ruwudu';

    /*SIZES*/
    --main--page-width: 70vw;
    --main-margin: 50px;
    --main-border-radius: 20px;
    --main-border-radius-s: 5px;
    --input-margin: 20px;

    /*MISC*/
    --shadow-light: 1px 3px 5px rgba(0,0,0,0.1);
    --shadow-dark: 7px 10px 10px rgba(0,0,0,0.1);
}

.App{
    display:flex;
    flex-direction: column;
    min-height: 100vh;
}

body{
    margin: 0px;
    height:100vh;
    overflow-x:hidden;
}

main{
    display:flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100vw;
}


main > div{
    margin-top:25vh;
    display:flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: var(--main--page-width);
}


/*FOOTER*/

footer{
    margin-top: 20vh;
    height: 30vh;
    width: 100vw;
    background-color: var(--main-purple);
    display: flex;
    justify-content: space-between;
    align-items: center;
    position:relative;
    bottom: 0;
    z-index: 50;
    overflow:hidden;
}
.footerInfo{
    width: 40vw;
    margin-left: 2vw;
}
.footerLinks{
    margin-right: 15vw;
    
}
.footerLinks a{
    color: var(--main-beige);
}

/*NAVBAR*/
nav{
    height: 12vh;
    background-color: var(--main-purple);

    display:flex;
    flex-direction: row;
    align-items:center;
    justify-content: space-between;

    box-shadow: var(--shadow);
    transition: all 0.4s; /* Adds a transition effect when the padding is decreased */
    position: fixed; /* Sticky/fixed navbar */
    width: 100%;
    top: 0; /* At the top */
    z-index: 99; 
}
.scrollisfalse, .scrollisfalsemobile{
    padding: 3vh 20px; /* Large padding which will shrink on scroll (using JS) */
}
.scrollistrue, .scrollistruemobile{
    padding: 0vh 20px;
}
.scrollistrue a{font-size: medium;}
.scrollistrue h1{font-size: x-large;}

.scrollisfalse h1{font-size: xx-large;}
.scrollisfalse a{font-size: x-large;}

nav h1{
    font-weight:800; 
}
nav > div{
    display:flex;
    width: 40%;
    justify-content: space-around;
}
nav > div a{
    color: var(--main-beige);
    position: relative;
    padding-bottom: 5px;
    font-size: larger;
}

/*USERWRAPPER*/
nav .userWrapper{
    width: 30%;
}
nav .userChoices:first-child{
    color: var(--main-beige);
}
nav .userChoices button {
    margin-left: 10px;
}
nav .dropDown{
    border-bottom: 20px;
    position:absolute;
    left: 78%;
    background-color: var(--main-beige);
    width:11%;
}

nav .dropDown a{
    width:100%;
    display:block;
    border-bottom: 0px;
}
nav .dropDown a,
nav .dropDown p{
    font-size: medium;
    color: var(--main-purple);
}

nav .dropDown p{
    transition: all 0.2s ease-in;
}

nav .userWrapper :first-child{
    cursor: default;
}

nav .userWrapper :last-child{
    cursor: pointer;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}
nav .dropDown a:hover,
nav .dropDown p:hover{
    background-color: var(--main-purple);
    color: var(--main-beige);
}
nav .dropDown p{
    margin: 0px;
    padding: 10px 0px 10px 15px;
}

/*mobilanpassning*/

nav.mobile{
    display:flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}
nav.mobile > h1{
    font-size: 40px;
    margin-left:10%;
}
nav.mobile .middlespace{width: 80%;}

nav.mobile .hamburger{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    right: -7px;
    z-index: 53;
}
nav.mobile .hamburger div{
    background-color: var(--main-beige);
    width: 50px;
    height: 5px;
    margin: 5px;
    border-radius: 2px;
}

nav.mobile .hamburger > div::before,
nav.mobile .hamburger > div::after{
    content: '';
    position: absolute;
    top: -10px;
    width: 100%;
    height: 2px;
    transition: all  0.4s ease;
}
nav.mobile .hamburger > div::after{
    top: 10px;
}
nav.mobile .hamburger > div::before{
    top: 0;
    transform: rotate(45deg);
}
nav.mobile .hamburger > div::after{
    top: 0;
    transform: rotate(135deg);
}
nav.mobile .sidebar{
    display: flex;
    flex-direction: column;
    font-size: 20px;
    transition: all .5s ease-in-out;

}
nav.mobile .userWrapper{
    width: 100%;
    bottom: 13vh;
    position: absolute;
    display: flex;
    align-items: center;
    flex-direction: column;
}
nav.mobile .userWrapper a,
nav.mobile .userWrapper p
{
    font-size: 16px;
    color: var(--main-beige);
}
nav.mobile .divider{
    width: 70%;
    background-color: var(--accent-purple);
    height: 2px;
    margin-top: 20px;
}
nav.mobile .links{
    position: absolute;
    display:flex;
    flex-direction: column;
    z-index: 54;
    top: 110vh;
    align-items: center;
}
nav.mobile .links a{
    margin: 6vh;
    width: 80%;
    text-align: center;
}

nav.mobile .openistrue{
    background-color: var(--main-purple);
    height: 200vh;
    position:fixed;
    right: -0px;
    z-index: 52;
    align-items: center;
}
nav.mobile .backdroptrue{
    width: 200vw;
    height:200vh;
    position:absolute;
    pointer-events: auto;
    z-index: 51;
    background-color: rgba(0, 0, 0, 0.299);
}
nav.mobile .openisfalse{
    position: absolute;
    right:-50vw;
}

/*NOT FOUND*/

.notFound{
    margin-top: 10vh;
}
.notFound img{
    width: 100vw;
}


/* RESTERANDE ELEMENT*/


a{text-decoration: none; cursor:pointer;}
button{cursor:pointer;}

/*FORM*/
/*INPUT*/
input,
input[type="radio"] + label,
input[type="checkbox"] + label:before,
select option,
select, textarea {

    padding: 1em;
    line-height: 1.4;
    background-color: var(--input-bgc);
    border: 1px solid #e5e5e5;
    border-radius: 3px;
    -webkit-transition: 0.35s ease-in-out;
    -moz-transition: 0.35s ease-in-out;
    -o-transition: 0.35s ease-in-out;
    transition: 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
}

input, textarea{
    margin-bottom: var(--input-margin);
}

input:focus,
textarea:focus {
    outline: 0;
    border-color: var(--accent-purple);
    }

/*SELECT*/
select:focus,
select:active {
    outline: 0;
}
select option {
    background-color: var(--accent-purple);
    color: #fff;
}

body main p, body main li{font-family: var(--main-body-text);}

nav, h1,h2,h3,h4,h5,h6{
    font-family: var(--main-title-text);
    font-weight: 700;
}

p, li{
    font-size:large;
}

.bold{font-weight: bold;}

.highlightPurple{
    color: var(--main-purple);
    transition: color .1s ease-in;
}

.highlightPurple:hover{
    color: var(--accent-purple);
    cursor: pointer;
}
.highlightPurple a{
    color: var(--main-purple);
    transition: color .1s ease-in;
}

.highlightPurple a:hover{
    color: var(--accent-purple);
    cursor: pointer;
}

/*MOBILE WIDTH*/

/* On screens that are 732 or less */
@media screen 
    and (max-width: 732px) 
    and (min-width: 300px) 
{ 
    footer{
        display:flex;
        flex-direction: column;
        text-align: center;
    }
    footer .footerLinks{
        margin: 0px;
        position: relative;
        bottom: 2vh;
        height: 70%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .footerLinks h4{
        margin-top: 2vh;
        font-size: 16px;
    }
    .footerInfo{
        position:relative;
        margin-left:0px;
    }
    main > div h1{
        font-size: 30px;
        
    }
    #videoWrapper .introWrapper h1 {
        font-size: 14vw;
    }
    .introWrapper p{
        font-size: 16px;
        width: 70vw;
    }
}