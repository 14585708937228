#omOssWrapper{
    display:flex;
    align-items: center;
    flex-direction: column;
    width: var(--main--page-width)
}
#omOssWrapper > p{
    text-align: center;
}

/*PERSONER*/
#personListWrapper{
    display:flex;
    justify-content: space-between;
    width:100%;
    margin-top: var(--main-margin);

}
.person{
    width: 20vw;
}
.person h3{text-align: center;}
.person img{
    width:100%;
    background-position: center;
    background-size: cover;
    border-radius: 150px;
    box-sizing: border-box;
    border: 0px solid white;
    transition: border .1s ease 0.1s;
}

.person img:hover{
    border: 8px solid var(--accent-green);
}

/* On screens that are 732 or less */
@media screen 
    and (max-width: 732px) 
    and (min-width: 300px) 
{ 
    #personListWrapper{
        flex-direction: column;
        align-items: center;
    }
    .person{
        width: 50vw;
        border-top: 2px solid var(--main-purple);
        border-radius: 2px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .person img{
        margin-top: 40px;
    }
    .person p{
        text-align: center;
    }
}

